<template>
    <v-container style="height: 100%">
        <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center" cols="12">
                Loading Account
            </v-col>
            <v-col cols="6">
                <v-progress-linear
                    height="10"
                    indeterminate
                    striped
                    color="getac"
                ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: `OidcCallback`,

    mounted() {
        window.location.replace(window.location.origin)
    }
}
</script>
